import React, { useEffect, useState } from "react";
import MediaObject from "../../components/MediaObject";
import UserPhotos from "../../assets/images/user-photo.png";
import Cart from "../../assets/images/Buy.svg";
import styled from "styled-components";
import { useCartContext } from "../../context/CartContext";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/crown2.svg";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useAuthContext } from "../../context/AuthContext";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import BPProducts from "../../components/Products/BPProducts";
import { useOrderContext } from "../../context/OrderContext";
import LinearProgress from "@mui/material/LinearProgress";
function BPDashboard() {
  const { cartItems } = useCartContext();
  const { bpLoginInfo } = useAuthContext();
  const {
    getLatestOrdersBP,
    latestOrdersBP,
    getTargetCampaignMetricsBP,
    targetMetrics,
  } = useOrderContext();
  const [products, setProducts] = useState([]);
  const [nav1Value, setNav1Value] = useState(0);
  const [nav2Value, setNav2Value] = useState(0);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  useEffect(() => {
    const getAllProducts = async () => {
      try {
        const { data } = await axiosPrivate(`/bp/products/get-all`, {
          headers: {
            Authorization: `Bearer ${bpLoginInfo?.token?.access_token}`,
          },
        });
        setProducts(data?.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    getAllProducts();
  }, [bpLoginInfo, axiosPrivate]);

  // useEffect(() => {
  //   getLatestOrdersBP();
  // }, []);

  const handleNavigation = (event, newValue) => {
    setNav1Value(newValue);
    if (newValue === 1) {
      navigate("/customer/search");
    } else if (newValue === 2) {
      navigate("/order-history/bp");
    }
  };

  //target metrics
  useEffect(() => {
    getTargetCampaignMetricsBP();
  }, []);

  return (
    <Wrapper>
      <Header>
        <MediaObject
          UserPhoto={UserPhotos}
          UserName={bpLoginInfo?.customer?.name}
          companyName={bpLoginInfo?.customer?.company_details
            ?.map((company) => company.company_name)
            .slice(0, 1)
            ?.join(" | ")}
          Location=""
          companies={bpLoginInfo?.customer?.company_details}
          renderFrom="bp"
        />
        <CartContainer>
          <Link to="/cart/bp">
            <img src={Cart} alt="Cart" />
            <p className="cart-amount">{cartItems?.length}</p>
          </Link>
        </CartContainer>
      </Header>
      <Link to="/order-history/bp" state={{ renderFrom: "assigned" }}>
        <Card
          sx={{
            background: "radial-gradient(circle, #651fff, #00c9ff)", // Radial gradient background
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "400px",
            width: "100%",
            height: "80px",
            mt: "10px",
            textAlign: "center",
            boxShadow: "rgba(0, 0, 0, 0.3) -4px 9px 25px -6px",
            borderRadius: "10px",
          }}
        >
          <CardHeader
            sx={{ color: "white" }}
            titleTypographyProps={{ variant: "h4", fontWeight: "bold" }}
            title={
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
              >
                {targetMetrics?.target_orders ?? 0}
                <Typography variant="body2" sx={{ ml: 1 }}>
                  {`(${targetMetrics?.target_fulfillment_percentage ?? 0} %)` ||
                    0}
                </Typography>
              </Box>
            }
            subheaderTypographyProps={{ color: "white", letterSpacing: "2px" }}
            subheader="Total Target Orders"
          />
          <CrisisAlertIcon
            sx={{ fontSize: 60, opacity: "0.1", position: "absolute" }}
          />
        </Card>
      </Link>

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 1 }}>
        <Grid item xs={6}>
          <Card
            sx={{
              height: "100px", // Increased height to accommodate the progress bar
              background: "linear-gradient(90deg, #f93122, #e0944c)", // Gradient background
              margin: "10px 0 10px 0",
              color: "white",
              boxShadow:
                "rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
              borderRadius: "10px",
              position: "relative", // Ensure card content positioning is relative
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                padding: "8px",
                height: "100%", // Ensure content fills the card height
                position: "relative", // Positioning relative for internal absolute elements
              }}
            >
              <AssignmentReturnedIcon
                sx={{
                  fontSize: 60,
                  opacity: "0.15",
                  position: "absolute",
                  top: 10,
                }}
              />
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  whiteSpace: "nowrap",
                  fontWeight: "bold",
                  marginBottom: "4px",
                  position: "relative", // Ensure positioning relative to avoid overlap with progress bar
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                >
                  {targetMetrics?.total_orders ?? 0}
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    {`(${targetMetrics?.achieved_order_percentage ?? 0} %)` ||
                      0}
                  </Typography>
                </Box>
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
                component="div"
                sx={{
                  whiteSpace: "nowrap",
                  marginTop: "2px",
                  letterSpacing: "2px",
                  position: "relative", // Ensure positioning relative to avoid overlap with progress bar
                }}
              >
                Placed Orders
              </Typography>

              {/* Progress Bar */}
              <LinearProgress
                variant="determinate"
                value={targetMetrics?.achieved_order_percentage ?? 0}
                sx={{
                  width: "100%",
                  position: "absolute",
                  bottom: 0, // Position the bar at the bottom of the card
                  left: 0,
                  height: "5px",
                  borderRadius: "5px",
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#e2f83a",
                  },
                }}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card
            sx={{
              height: "100px", // Increased height to accommodate the progress bar
              background: "linear-gradient(45deg, #32a64b, #b1d76b)", // Gradient background
              color: "white",
              margin: "10px 0 10px 0",
              boxShadow:
                "rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
              borderRadius: "10px",
              position: "relative", // Make sure the card content is positioned relatively
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                padding: "8px",
                position: "relative", // Positioning relative for the progress bar
                height: "100%", // Make sure content takes full height
              }}
            >
              <AssignmentTurnedInIcon
                sx={{ fontSize: 60, opacity: "0.1", position: "absolute" }}
              />
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  whiteSpace: "nowrap",
                  fontWeight: "bold",
                  marginBottom: "4px",
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                >
                  {targetMetrics?.fulfilled_orders ?? 0}
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    {`(${
                      targetMetrics?.achieved_fulfillment_percentage ?? 0
                    } %)` || 0}
                  </Typography>
                </Box>
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
                component="div"
                sx={{
                  whiteSpace: "nowrap",
                  marginTop: "2px",
                  letterSpacing: "2px",
                }}
              >
                Fulfilled Orders
              </Typography>

              {/* Progress Bar */}
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: "5px",
                  borderRadius: "0 0 10px 10px", // Rounded corners at the bottom
                }}
              >
                <LinearProgress
                  variant="determinate"
                  value={targetMetrics?.achieved_fulfillment_percentage ?? 0}
                  sx={{
                    height: "100%",
                    borderRadius: "0 0 10px 10px",
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#e2f83a",
                    },
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <BPProducts products={products} />
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 999 }}
      >
        <BottomNavigation
          showLabels
          value={nav1Value}
          onChange={handleNavigation}
        >
          <BottomNavigationAction label="Dashboard" icon={<DashboardIcon />} />
          <BottomNavigationAction
            label="Place Order"
            icon={<AddShoppingCartIcon />}
          />
          <BottomNavigationAction
            label="Order History"
            icon={<ManageHistoryIcon />}
          />
        </BottomNavigation>
      </Paper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 10px 0;
  position: relative;
  .checkout {
    position: fixed;
    bottom: 4%;
    left: 0;
    right: 0;
  }
  a {
    text-decoration: none;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  margin: 0 16px;
`;

const CartContainer = styled.section`
  position: relative;

  .cart-amount {
    position: absolute;
    top: -30%;
    right: -40%;
    border: 1px solid;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    background-color: #ee3d3c;
    color: white;
  }
`;

export default BPDashboard;
