import React from "react";
import styled from "styled-components";

export default function ButtonBlock({ children, isDisabled,startIcon }) {
  return (
    <Wrapper>
      <button disabled={isDisabled}>{startIcon}{children}</button>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: none;
    width: 100%;
    height: 40px;
    border: 1px solid #db4d45;
    border-radius: 100px !important;
    background-color: #db4d45;
    color: #fcfcfc;
    text-transform: capitalize;
    font-size: 15px;
    letter-spacing: 1px;
    border: 0;
    font-weight: 700;
    border-style: solid;
    cursor: pointer;
    text-align: center;
    max-width: 200px;
    z-index: 99999;
    margin: 5px 0 5px 0;
    &:focus,
    &:active {
      outline: 0;
    }
    &:disabled {
      background-color: #d4d1d1;
      color: #7b7a82;
      cursor: no-drop;
    }
  }
`;
