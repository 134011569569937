import React from "react";
import styled from "styled-components";
import { convertToBanglaNumber } from "../helpers/convert";
import { useAppContext } from "../context/AppContext";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import OrderHistoryDetailsModal from "./Products/OrderHistoryDetailsModal";
import moment from "moment";
import { useTranslation } from "react-i18next";

function ProductCardForHistory({
  id,
  order_no,
  total_price,
  created_at,
  order_items,
  is_paid,
  customer
}) {
  const { lang } = useAppContext();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  return (
    <Wrapper>
      <TableContainer onClick={handleOpen} className="table_container">
        <Table aria-label="product table" sx={{ border: "none" }}>

          <TableBody sx={{position:"relative"}}>
            <TableRow>
              <TableCell component="th" scope="row" className="content_title">
                {t("order_no")} 
              </TableCell>
              <TableCell>{order_no} </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" className="content_title">
                {t("total")}
              </TableCell>
              <TableCell>
                {" "}
                {lang?.checkedA
                  ? "৳" + convertToBanglaNumber(total_price.toFixed(2))
                  : total_price + " Tk"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" className="content_title">
                {t("order_date")}
              </TableCell>
              <TableCell>
                {lang?.checkedA
                  ? convertToBanglaNumber(
                      moment(created_at).format("DD-MM-YYYY hh:mm:a")
                    )
                  : moment(created_at).format("DD-MM-YYYY")}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <OrderHistoryDetailsModal
        handleOpen={handleOpen}
        open={open}
        setOpen={setOpen}
        id={id}
        order_items={order_items}
        customer={customer}
        is_paid={is_paid}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-radius: 16px;
  box-sizing: border-box;
  padding: 5px 0;
  /* position: relative; */

  .table_container {
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;

    &:hover {
      background-color: #efeaea; /* Slightly darker gray on hover */
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    }

    .content_title {
      font-weight: 700;
    }

    .pay_status{
      position: absolute;
     left: 30%;
      top: 45%;
      text-transform: uppercase;
      opacity: 0.4;
      transform: rotate(315deg);
      .delivered{
        border:2px solid green;
        color: green;
        padding: 5px;
        border-radius: 12px;
      }

      .pending{
        border:2px solid #db4d45;
        color: #db4d45;
        padding: 5px;
        border-radius: 12px;
      }
    }
  }
`;

export default ProductCardForHistory;
