import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import WarningIcon from "@mui/icons-material/Warning"; // Example icon import
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialog({ open, setOpen, placeOrder }) {
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOrder = () => {
    placeOrder();
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          boxShadow: "5px 35px 20px rgb(0 0 0 / 0.9)", // Increase shadow for a more prominent effect
          borderRadius: "10px",
          maxWidth: "400px", // Set max-width for the dialog
          margin: "auto", // Center the dialog
          padding: "10px", // Add padding
        }}
      >
        <DialogTitle
          sx={{
            color: "#ee3d3c",
            fontSize: "14px",
            fontWeight: "600",
            textAlign: "center", // Center the title text
            display: "flex",
            alignItems: "center", // Align icon and text vertically
            justifyContent: "between", // Center the content horizontally
            gap: "1px", // Add space between icon and text
          }}
        >
          <WarningIcon sx={{ fontSize: "18px" }} /> {/* Add icon before text */}
          {t("duplicate_title")}
        </DialogTitle>
        <DialogContent
          sx={{
            padding: "5px", // Add padding to content
          }}
        >
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{
              color: "#333", // Set text color
              fontSize: "14px", // Set font size
              textAlign: "center", // Center the content text
              display: "flex",
              alignItems: "center", // Align icon and text vertically
              justifyContent: "center", // Center the content horizontally
              gap: "10px", // Add space between icon and text
            }}
          >
            {t("duplicate_sub_title")}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "space-between", // Space between buttons
            padding: "10px 20px", // Add padding to actions
          }}
        >
          <Button
            onClick={handleClose}
            sx={{
              color: "white",
              backgroundColor: "gray",
              "&:hover": {
                backgroundColor: "darkgray",
              },
            }}
          >
            {t("no")}
          </Button>
          <Button
            onClick={handleOrder}
            sx={{
              color: "white",
              backgroundColor: "#ee3d3c",
              "&:hover": {
                backgroundColor: "darkred",
              },
            }}
          >
            {t("yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
