export function convertToBanglaNumber(number) {
    const banglaDigits = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯'];

    const convertDigit = digit => banglaDigits[digit];

    return number?.toString().replace(/\d/g, digit => convertDigit(digit));
}

export function banglaLoginErrorMessage(message, lang = null) {
    if (message?.startsWith("MSISDN")) {
        if (lang.checkedA) {
            return "মোবাইল নম্বর ১১ অক্ষরের হতে হবে";
        }
        else {
            return message;
        }
    }
    if (message?.startsWith("Invalid Phone")) {
        if (lang.checkedA) {
            return "ইনভ্যালিড ফোন নম্বর";
        }
        else {
            return message;
        }
    }
    if (message?.startsWith("Invalid PIN")) {
        if (lang.checkedA) {
            return "ইনভ্যালিড পিন";
        }
        else {
            return message;
        }
    }
    if (message?.startsWith("Invalid ID")) {
        if (lang.checkedA) {
            return "ইনভ্যালিড আইডি ";
        }
        else {
            return message;
        }
    }
    if (message?.startsWith("Please enter your PIN")) {
        if (lang.checkedA) {
            return "আপনার পিন লিখুন";
        }
        else {
            return message;
        }
    }
    if (message?.startsWith("You already have")) {
        if (lang.checkedA) {
            return "আপনার ইতিমধ্যে একটি অ্যাকাউন্ট আছে, অনুগ্রহ করে পিন দিয়ে লগইন করুন";
        }
        else {
            return message;
        }
    }
    if (message?.startsWith("OTP Sent")) {
        if (lang.checkedA) {
            return "OTP সফলভাবে পাঠানো হয়েছে";
        }
        else {
            return message;
        }
    }
    if (message?.startsWith("User does not exist")) {
        if (lang.checkedA) {
            return "ব্যবহারকারী কোন অস্তিত্ব নেই";
        }
        else {
            return message;
        }
    }
    if (message?.startsWith("Invalid Credentials")) {
        if (lang.checkedA) {
            return "ইনভ্যালিড ইউজারনেম অথবা পাসওয়ার্ড";
        }
        else {
            return message;
        }
    }
    if (message?.startsWith("Username is required")) {
        if (lang.checkedA) {
            return "আপনার ইউজারনেম লিখুন";
        }
        else {
            return message;
        }
    }
    if (message?.startsWith("Please enter your password")) {
        if (lang.checkedA) {
            return "আপনার পাসওয়ার্ড লিখুন";
        }
        else {
            return message;
        }
    }
    if (message?.startsWith("Password must be at least 8 characters")) {
        if (lang.checkedA) {
            return "ইনভ্যালিড পাসওয়ার্ড";
        }
        else {
            return message;
        }
    }
    if (message?.startsWith("Duplicate orders are not allowed")) {
        if (lang.checkedA) {
            return "ডুপ্লিকেট অর্ডার করা যাবে না";
        }
        else {
            return message;
        }
    }
    if (message?.startsWith("Order created successfully")) {
        if (lang.checkedA) {
            return "সফলভাবে অর্ডার করা হয়েছে";
        }
        else {
            return message;
        }
    }
    if (message?.startsWith("No more orders are allowed")) {
        if (lang.checkedA) {
            return "ডুপ্লিকেট অর্ডার করা যাবে না";
        }
        else {
            return message;
        }
    }
}