import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Tooltip,
  Typography,
  ListItemIcon,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAuthContext } from "../context/AuthContext";
import { useCartContext } from "../context/CartContext";
import PropTypes from "prop-types";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

function MediaObject(props) {
  const { UserName, companyName, companies, renderFrom } = props;
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();
  const { logout, bPlogout } = useAuthContext();
  const { setCartItems } = useCartContext();

  const logoutHandler = async () => {
    if (renderFrom === "bp") {
      await bPlogout();
      navigate("/bp/login");
    } else {
      await logout();
      setCartItems([]);
      navigate("/login");
    }
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Wrapper>
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title="Open settings">
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar sx={{ bgcolor: "#EF574B" }} alt="No Image" src="">
              {UserName?.charAt(0).toUpperCase()}
            </Avatar>
          </IconButton>
        </Tooltip>
        <StyledMenu
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <StyledMenuItem onClick={logoutHandler}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            Logout
          </StyledMenuItem>
        </StyledMenu>
      </Box>
      <div className="customer_details">
        <strong>{UserName}</strong>
        {companies?.length > 1 ? (
          <div>
            <Typography
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              sx={{ cursor: "pointer" }}
            >
              {companyName}...
            </Typography>
            <Popover
              id="mouse-over-popover"
              sx={{ pointerEvents: "none" }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              {companies?.map((company) => (
                <Typography key={company?.company_name} sx={{ p: 1 ,fontSize:"13px",display:"flex",alignItems:"center",justifyContent:"start"}}>
                  <AccountBalanceIcon sx={{fontSize:"12px",marginRight:"3px"}}/> {company?.company_name}
                </Typography>
              ))}
            </Popover>
          </div>
        ) : (
          <div>{companyName}</div>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
  .customer_details {
    margin-left: 16px;
    text-transform: capitalize;
  }
  strong {
    font-size: 20px;
    display: block;
  }
  p {
    font-size: 12px;
    font-weight: 600;
    margin: 0;
  }
`;

const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  &:hover {
    background-color: #f5f5f5;
  }
  & .MuiListItemIcon-root {
    min-width: 36px;
  }
`;

export default MediaObject;
