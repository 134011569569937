import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { apiSecret, baseAxios, baseURL } from "../../configs/configs";
import ButtonBlock from "../../components/Buttons/ButtonBlock";
import { toast } from "react-hot-toast";
import {
  Avatar,
  Card,
  CardActions,
  CardHeader,
  CircularProgress,
  Typography,
  Button,
  Paper,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import TelInput from "../../components/CustomInputs/TelInput";
import OtpBox from "../../components/OtpBox";
import Topbar from "../../components/Topbar";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context/AppContext";
import { banglaLoginErrorMessage } from "../../helpers/convert";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Carousel from "react-material-ui-carousel";
import Searchbar from "../../components/Searchbar";
import axios from "axios";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import SingleCustomerOrderHistory from "../../components/BP/SingleCustomerOrderHistory";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";

function BPSearchPage() {
  const navigate = useNavigate();
  const { lang, carousel } = useAppContext();
  const { bpLoginInfo } = useAuthContext();
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const [searchedCustomers, setSearchedCustomers] = useState(null);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOrders, setShowOrders] = useState(false);
  const [nav1Value, setNav1Value] = useState(1);
  const carousels = carousel?.filter(
    (s) => s?.app_location?.toLowerCase() === "login_page" && s?.for_application?.toLowerCase() === "bp_app"
  );

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${baseURL}/bp/search-user`,
        {
          search_string: query,
          company_ids: bpLoginInfo?.customer?.company_details?.map((c) => c.id),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bpLoginInfo?.token.access_token}`,
          },
        }
      );
      setSearchedCustomers(data?.data);
      localStorage.setItem("selected_customer", JSON.stringify(data?.data));
      setLoading(false);
      setMessage(data?.message);
    } catch (error) {
      setSearchedCustomers(null);
      setLoading(false);
      setMessage(error?.response.data.message);
    }
  };

  const handleNavigation = (event, newValue) => {
    setNav1Value(newValue);
    if (newValue === 0) {
      navigate("/dashboard/bp");
    } else if (newValue === 2) {
      navigate("/order-history/bp");
    }
  };
  return (
    <Wrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "start",
          marginLeft: "20px",
        }}
      >
        <div
          className="backbutton"
          onClick={() => navigate("/dashboard/bp")}
          style={{ marginRight: "10px", cursor: "pointer" }}
        >
          <KeyboardBackspaceIcon />
        </div>
        <h3>Search</h3>
      </div>
      <UpperPart>
        <Carousel
          animation="slide"
          indicators={carousels?.length > 1 ? true : false}
          navButtonsAlwaysVisible={false}
          navButtonsProps={{
            style: {
              display: "none",
              opacity: 0,
            },
          }}
        >
          {carousels?.map((c, idx) => (
            <div className="img-container" key={idx}>
              <img src={c["image_url"]} alt="" height="100%" width="100%" />
            </div>
          ))}
        </Carousel>


      </UpperPart>
      <LoginPart>
        <h4>{t("bp_search_title")}</h4>
        {/* <p>{t("login_subtitle_bp")}</p> */}
        <form onSubmit={handleSearch}>
          <Searchbar query={query} setQuery={setQuery} />
        </form>

        {loading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
            <h5>Searching User...</h5>
          </div>
        ) : searchedCustomers ? (
          <div>
            <Card sx={{ margin: "10px 0" }}>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: "red" }} aria-label="recipe">
                    {searchedCustomers?.name.slice(0, 1)}
                  </Avatar>
                }
                title={`${searchedCustomers?.name}`}
                titleTypographyProps={{ variant: "h6", color: "#651fff" }}
                subheader={
                  <>
                    <p>
                      ID - {searchedCustomers?.emp_id} |{" "}
                      {searchedCustomers?.company.company_name}
                    </p>
                    <p>
                      Shera ID - <strong>{searchedCustomers?.username}</strong>
                    </p>
                  </>
                }
              />
              <div className="action_buttons">
                <a
                  href="#"
                  onClick={() => setShowOrders(!showOrders)}
                  className="view"
                >
                  {" "}
                  {showOrders ? "Hide" : "Show"} Orders{" "}
                  {showOrders ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </a>
                {/* <Link to='/order-history/bp' state={{ renderFrom: "products" }}  className="view">View Orders</Link> */}
                <Link to="/products/bp" className="place">
                  <AddShoppingCartIcon /> Place Order
                </Link>
              </div>
            </Card>
          </div>
        ) : (
          <p style={{ textAlign: "center", padding: "5px", marginTop: "20px" }}>
            {message}
          </p>
        )}
      </LoginPart>
      {showOrders && (
        <SingleCustomerOrderHistory searchedCustomer={searchedCustomers} />
      )}

      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 999 }}
      >
        <BottomNavigation
          showLabels
          value={nav1Value}
          onChange={handleNavigation}
        >
          <BottomNavigationAction label="Dashboard" icon={<DashboardIcon />} />
          <BottomNavigationAction
            label="Place Order"
            icon={<AddShoppingCartIcon />}
          />
          <BottomNavigationAction
            label="Order History"
            icon={<ManageHistoryIcon />}
          />
        </BottomNavigation>
      </Paper>
    </Wrapper>
  );
}
const UpperPart = styled.div`
  .img-container {
    width: 100%;
    max-width: 370px;
    margin: auto;
    /* height: 320px; */
    padding: 5px;
    overflow: hidden;
    /* display: grid; */
    justify-content: center;
    align-items: center;
    line-height: 2px;
    border: 1px solid #d8d8d8;
    border-radius: 20px;
  }
  img {
    width: 100%;
    height: 250px;
    /* object-fit: cover; */
    border-radius: 20px;
  }
`;

const LoginPart = styled.div`
  line-height: 50px;
  background-color: #ffffff;
  max-width: 424px;
  padding: 0 15px;
  border-radius: 10px 10px 0 0;

  h2 {
    font-size: 25px;
  }
  p {
    color: #868889;
  }
  .action_buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    a {
      /* color: #7008d7; */
      padding: 0 5px;
      /* background-color: #f3eeee; */
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 5px;
      transition: all 0.3s ease;
      border-radius: 5px;
      font-size: 14px;
    }
    .view {
      background: #8451f0;
      color: white;
    }

    .place {
      color: #651fff;
      background-color: white;
      border: 1px solid #8451f0;
      &:hover {
        background: #8451f0;
        color: white;
      }
    }
  }
`;
const Wrapper = styled.div`
  a {
    text-decoration: none;
  }
`;

export default BPSearchPage;
