import React, { useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useAuthContext } from "./AuthContext";

const OrderContext = React.createContext();

export const OrderProvider = ({ children }) => {
    const { customerLoginInfo, bpLoginInfo } = useAuthContext();
    const access_token = customerLoginInfo?.token ? customerLoginInfo?.token?.access_token : bpLoginInfo?.token?.access_token
    const axiosPrivate = useAxiosPrivate();

    const [latestOrders, setLatestOrders] = useState([]);
    const [latestOrdersBP, setLatestOrdersBP] = useState(null)
    const [totalPage, setTotalPage] = useState(0);
    const [targetMetrics, setTargetMetrics] = useState(null);

    const getLatestOrdersBP = async (page = 1, selectedTab = 0, renderFrom, searchedCustomer) => {

        try {

            const { data } = await axiosPrivate(
                `/bp/orders/${renderFrom == 'assigned' ? 'get-self-orders' : 'get-by-company-campaign'}?page=${page}&status=${selectedTab == 0 ? 'pending' : 'delivered'}`,

                { headers: { Authorization: `Bearer ${access_token}` } }
            );

            if (searchedCustomer) {
                const sheraId = searchedCustomer?.username
                const { data } = await axiosPrivate(
                    `/bp/orders/get-by-company?page=${page}&status=${selectedTab == 0 ? 'pending' : 'delivered'}&shera_id=${sheraId}`,

                    { headers: { Authorization: `Bearer ${access_token}` } }
                );
                setLatestOrdersBP(data?.data?.orders);
                setTotalPage(data?.data?.pages)
            }
            else {
                setLatestOrdersBP(data?.data?.orders);
                setTotalPage(data?.data?.pages)
            }

        } catch (error) {
            console.log(`Error in fetching BP Orders ${error}`)
        }

    };

    const getTargetCampaignMetricsBP = async () => {
        try {
            const { data } = await axiosPrivate(
                `/bp/campaign/target-metrics`,
                { headers: { Authorization: `Bearer ${access_token}` } }
            );
           setTargetMetrics(data?.data)
        } catch (error) {
            console.log(`Error in fetching BP TargetCampaignMetrcs ${error}`)
        }
    };


    return (
        <OrderContext.Provider
            value={{
                getLatestOrdersBP,
                latestOrders,
                latestOrdersBP,
                totalPage,
                getTargetCampaignMetricsBP,
                targetMetrics

            }}
        >
            {children}
        </OrderContext.Provider>
    );
};

export const useOrderContext = () => {
    return useContext(OrderContext);
};
