import React from "react";
import ReactDOM from "react-dom/client";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import "./index.css";
import App from "./App";
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./components/PrivateRoute";
import Registration from "./pages/RegistrationPage/Registration";
import RegistrationComplete from "./pages/RegistrationPage/RegistrationComplete";
import VerifyOTP from "./pages/VerifyOTP";
import NotFound from "./components/404";
import LoginPage from "./pages/LoginPage";
import TokenProtectedRoute from "./components/TokenProtectedRoute";
import { AppProvider } from "./context/AppContext";
import Slider from "./pages/Slider";
import { CartProvider } from "./context/CartContext";
import Cart from "./pages/Product/Cart";
import Products from "./pages/Product/Products";
import Order from "./pages/Product/Order";
import LoginPageBP from "./pages/LoginPageBP";
import BPSearchPage from "./pages/BP/BpSearchPage";
import RegistrationCompleteBP from "./pages/RegistrationPage/RegistrationCompleteBP";
import BPDashbaord from "./pages/BP/BPDashboard";
import BpProtectedRoute from "./components/BpProtectedRoute";
import ProductsBP from "./pages/Product/ProductsBP";
import CartBP from "./pages/Product/CartBP";
import OrderBP from "./pages/Product/OrderBP";
import OrderHistory from "./pages/Product/OrderHistory";
import { OrderProvider } from "./context/OrderContext";
import OrderHistoryBP from "./pages/Product/OrderHistoryBP";


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index path="/" element={<Slider />} />
      <Route index path="/login" element={<LoginPage />} />
      <Route index path="/bp/login" element={<LoginPageBP />} />
      <Route index path="/registration" element={<Registration />} />

      <Route element={<TokenProtectedRoute />}>
        <Route index path="/verify-otp" element={<VerifyOTP />} />
        <Route index path="/registration-complete" element={<RegistrationComplete />} />
      </Route>

      <Route path="/" element={<PrivateRoute />}>
        {/* <Route index path="/welcome" element={<Welcome />} /> */}
        <Route index path="/cart" element={<Cart />} />
        <Route index path="/order" element={<Order />} />
        <Route index path="/products" element={<Products />} />
        <Route index path="/order-history" element={<OrderHistory />} />
      </Route>

      <Route element={<BpProtectedRoute />}>
        <Route index path="/dashboard/bp" element={<BPDashbaord />} />
        <Route index path="/customer/search" element={<BPSearchPage />} />
        {/* <Route index path="/registration-complete/bp" element={<RegistrationCompleteBP />} /> */}
        <Route index path="/cart/bp" element={<CartBP />} />
        <Route index path="/order/bp" element={<OrderBP />} />
        <Route index path="/products/bp" element={<ProductsBP />} />
        <Route index path="/order-history/bp" element={<OrderHistoryBP />} />
      </Route>

      <Route path="/*" element={<NotFound />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AppProvider>
    <AuthProvider>
      <CartProvider>
        <OrderProvider>
        <RouterProvider router={router} />
        </OrderProvider>
      </CartProvider>
    </AuthProvider>
  </AppProvider>

);

serviceWorkerRegistration.register();
