import React, { useEffect, useState } from "react";
import MediaObject from "../../components/MediaObject";
import UserPhotos from "../../assets/images/user-photo.png";
import Cart from "../../assets/images/Buy.svg";
import styled from "styled-components";
import { useAuthContext } from "../../context/AuthContext";
import ButtonBlock from "../../components/Buttons/ButtonBlock";
import { useCartContext } from "../../context/CartContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ProductCardForHistory from "../../components/ProductCardForHistory";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { useOrderContext } from "../../context/OrderContext";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";

function OrderHistoryBP() {
  const { bpLoginInfo } = useAuthContext();
  const { cartItems } = useCartContext();
  let { state } = useLocation();
  const { latestOrdersBP, getLatestOrdersBP, totalPage } = useOrderContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(0);
  const [pendingPage, setPendingPage] = useState(1);
  const [deliveredPage, setDeliveredPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [nav1Value, setNav1Value] = useState(2);

  useEffect(() => {
    getLatestOrdersBP(currentPage, selectedTab, state?.renderFrom);
  }, [selectedTab, currentPage, state?.renderFrom]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setCurrentPage(newValue === 0 ? pendingPage : deliveredPage);
  };

  const handleNextPage = () => {
    const newPage = currentPage + 1;
    setCurrentPage(newPage);
    if (selectedTab === 0) {
      setPendingPage(newPage);
    } else {
      setDeliveredPage(newPage);
    }
  };

  const handlePreviousPage = () => {
    const newPage = currentPage - 1;
    setCurrentPage(newPage);
    if (selectedTab === 0) {
      setPendingPage(newPage);
    } else {
      setDeliveredPage(newPage);
    }
  };

  const handleNavigation = (event, newValue) => {
    setNav1Value(newValue);
    if (newValue === 0) {
      navigate("/dashboard/bp");
    } else if (newValue === 1) {
      navigate("/customer/search");
    }
  };
  return (
    <Wrapper>
      <Header>
        <MediaObject
          UserPhoto={UserPhotos}
          UserName={bpLoginInfo?.customer?.name}
          companyName={bpLoginInfo?.customer?.company_details
            ?.map((company) => company.company_name)
            .slice(0, 1)
            ?.join(" | ")}
          Location=""
          companies={bpLoginInfo?.customer?.company_details}
          renderFrom="bp"
        />
        <CartContainer>
          <Link to="/cart/bp">
            <img src={Cart} alt="Cart" />
            <p className="cart-amount">{cartItems?.length}</p>
          </Link>
        </CartContainer>
      </Header>

      <div className="order_history_title">
        <div className="order_history">
          <ListAltIcon />
          <h5>{ state?.renderFrom === 'assigned' ?t("self_latest_orders") : t("company_latest_orders")}</h5>
        </div>
        <div className="tabs-container">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            className="tabs"
            TabIndicatorProps={{ className: "tab-indicator" }}
          >
            <Tab label="Pending" className="tab" />
            <Tab label="Delivered" className="tab" />
          </Tabs>
        </div>
      </div>

      <>
        {latestOrdersBP?.length < 1 ? (
          <div className="no_order">
            <SentimentVeryDissatisfiedIcon
              sx={{ fontSize: "35px", color: "#db4d45" }}
            />
            <h2>Nothing Found</h2>
          </div>
        ) : (
          <div className="table_wrapper">
            {latestOrdersBP?.map((item) => (
              <ProductCardForHistory key={item.id} {...item} />
            ))}
            {
              <PaginationControls>
                <button
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  <ChevronLeftIcon />
                </button>
                <span>
                  {t("Page")} {currentPage} {t("of")} {totalPage}
                </span>
                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPage}
                >
                  <ChevronRightIcon />
                </button>
              </PaginationControls>
            }
          </div>
        )}
      </>
      {/* <form action="" onSubmit={() => navigate("/dashboard/bp")}>
        <div className="checkout">
          <ButtonBlock variant="contained" startIcon={<ArrowBackIcon />}>
            {t("back")}
          </ButtonBlock>
        </div>
      </form> */}
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 999 }}
      >
        <BottomNavigation
          showLabels
          value={nav1Value}
          onChange={handleNavigation}
        >
          <BottomNavigationAction label="Dashboard" icon={<DashboardIcon />} />
          <BottomNavigationAction
            label="Place Order"
            icon={<AddShoppingCartIcon />}
          />
          <BottomNavigationAction
            label="Order History"
            icon={<ManageHistoryIcon />}
          />
        </BottomNavigation>
      </Paper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 10px 0;
  position: relative;
  .checkout {
    position: fixed;
    bottom: 4%;
    left: 0;
    right: 0;
    z-index: 9999;
  }
  .order_history_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .order_history {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;
    margin: 16px 0 12px 0;
  }
  .tabs-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tabs {
    display: flex;
    gap: 5px; /* Adjust the gap between tabs */
  }

  .tab {
    font-size: 12px;
    min-width: 50px;
  }
  .tab-indicator {
    background-color: #ee3d3c;
  }
  .tab.Mui-selected {
    color: #ee3d3c;
  }
  .no_order {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
  }

  .table_wrapper {
    margin-bottom: 90px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  margin: 0 16px;
`;

const CartContainer = styled.section`
  position: relative;

  .cart-amount {
    position: absolute;
    top: -30%;
    right: -40%;
    border: 1px solid;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    background-color: #ee3d3c;
    color: white;
  }
`;

const PaginationControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  position: absolute;
  bottom: 10;
  left: 0;
  right: 0;
  button {
    background-color: #ee3d3c;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    &:disabled {
      background-color: #cccccc;
      cursor: not-allowed;
    }
  }
`;

export default OrderHistoryBP;
