import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CallIcon from "@mui/icons-material/Call";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import styled, { keyframes } from "styled-components";
import { convertToBanglaNumber } from "../../helpers/convert";
import { useAppContext } from "../../context/AppContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 400,
  bgcolor: "background.paper",
  p: 2,
  borderRadius: 8,
  boxShadow: "0 4px 16px rgba(0, 0, 0, 0.2)", // Enhanced shadow for better visual appeal
  backgroundImage: "linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)", // Gradient background
};

export default function OrderHistoryDetailsModal({
  handleOpen,
  open,
  setOpen,
  id,
  order_items,
  customer,
  is_paid,
}) {
  const handleClose = () => setOpen(false);
  const { lang } = useAppContext();
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <StyledTableContainer>
              {!is_paid && (
                <div className="customer_info">
                  <p className="customer_name">{customer?.name}</p>
                  <p className="customer_phone">
                    <AnimatedCallIcon sx={{ fontSize: "16px", marginRight: "3px" }} />{" "}
                    <a href={`tel:${customer?.phone}`}>{customer?.phone}</a>
                  </p>
                  <p className="shera_id">Shera ID: {customer.username}</p>
                </div>
              )}
              <StyledTable aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell sx={{ fontWeight: "bold" }}>
                      Product
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      Quantity
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      Amount
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order_items.map((row) => (
                    <StyledTableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <StyledTableCell component="th" scope="row">
                        {row.product.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {lang?.checkedA
                          ? convertToBanglaNumber(row.quantity)
                          : row.quantity}{" "}
                        {row.product.unit}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {lang?.checkedA
                          ? convertToBanglaNumber(row.total_price)
                          : row.total_price}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </StyledTableContainer>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
`;

const AnimatedCallIcon = styled(CallIcon)`
  animation: ${pulse} 1s infinite;
`;

const StyledTableContainer = styled(TableContainer)`
  width: 100%;
  margin: 0 auto;
  border-radius: 8px; /* Rounded corners */
  overflow: hidden;
  .customer_info {
    text-align: center;
    border-bottom: 2px solid #db4d45;
    padding: 10px;
    background-color: #fafafa;
  }
  .customer_name {
    font-size: 18px;
    font-weight: 700;
    margin: 0;
  }
  .customer_phone {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    font-size: 16px;
  }
  .shera_id {
    font-size: 14px;
    font-weight: 500;
    color: #555;
    margin-top: 5px;
  }
  .customer_phone a {
    text-decoration: none;
    color: #db4d45;
    font-weight: 600;
  }
  .customer_phone a:hover {
    text-decoration: underline;
  }
`;

const StyledTableCell = styled(TableCell)`
  font-size: 1rem; /* Adjust the font size */
  padding: 5px; /* Adjust padding */
  background-color: #ffffff; /* Background color */
`;

const StyledTable = styled(Table)`
  & .MuiTableCell-root {
    padding: 10px; /* Adjust padding */
  }
`;

const StyledTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #f9f9f9;
  }
  &:hover {
    background-color: #f1f1f1;
  }
  & th, & td {
    transition: background-color 0.3s ease; /* Smooth transition */
  }
`;
