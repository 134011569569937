import React, { useEffect } from "react";
import Carousel from "react-material-ui-carousel";
import styled from "styled-components";
import Topbar from "../components/Topbar";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../context/AuthContext";
import { useAppContext } from "../context/AppContext";
import { Skeleton } from "@mui/material";

export default function Slider() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { customerLoginInfo } = useAuthContext();
  const { carousel, carouselLoading } = useAppContext();

  const carousels = carousel?.filter(
    (s) => s?.app_location?.toLowerCase() === "landing_page" && s?.for_application?.toLowerCase() === "bp_app"
  );

  useEffect(() => {
    if (customerLoginInfo?.customer?.has_logged_in) {
      navigate("/products");
    }
  }, [customerLoginInfo]);

  return (
    <Wrapper>
      <Topbar></Topbar>
      {carouselLoading ? (
       <Skeleton variant="rounded" width={370} height={250} />
      ) : (
        <Carousel animation="slide">
          {carousels?.map((c, idx) => (
            <div className="img-container" key={idx}>
              <img src={c["image_url"]} alt="" height="100%" width="100%" />
              {c["short_desc"] && (
                <p className="slider-text">{c["short_desc"]}</p>
              )}
            </div>
          ))}
        </Carousel>
      )}

      <div className="welcome-text">
        <h2>{t("welcome_header")}</h2>
        <p>{t("everything_at_hand")}</p>
      </div>
      <div className="center-btn">
        {/* <button onClick={() => navigate("/login")} className="button">
          {t("get_started")}
        </button> */}
        <button
          onClick={() => navigate("/bp/login")}
          className="button"
        >
          {t("bp_login_button_text")}
        </button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .img-container {
    width: 100%;
    max-width: 370px;
    margin: auto;
    /* height: 320px; */
    padding: 5px;
    overflow: hidden;
    /* display: grid; */
    justify-content: center;
    align-items: center;
    line-height: 2px;
    border: 1px solid #d8d8d8;
    border-radius: 20px;
    .slider-text {
      text-align: center;
      color: #db4d45;
      padding: 20px 5px;
      font-weight: 600;
    }
    img {
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      border-radius: 20px;
      width: 100%;
      height: 250px;

      /* object-fit: cover; */
    }
  }

  .welcome-text {
    text-align: center;
    line-height: 30px;
    margin: 40px 0;
    h2 {
      margin-bottom: 15px;
    }
  }

  .center-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    .button {
      max-width: none;
      width: 100%;
      margin: 0 auto;
      height: 40px;
      border: 1px solid #db4d45;
      border-radius: 100px !important;
      background-color: #db4d45;
      color: #fcfcfc;
      text-transform: capitalize;
      font-size: 16px;
      letter-spacing: 1px;
      border: 0;
      font-weight: 700;
      border-style: solid;
      cursor: pointer;
      text-align: center;
      max-width: 200px;
      z-index: 99999;
      margin-bottom: 40px;
      &:focus,
      &:active {
        outline: 0;
      }
      &:disabled {
        background-color: #d4d1d1;
        color: #7b7a82;
        cursor: no-drop;
      }
    }
    .secondary-button {
      max-width: none;
      width: 90%;
      margin: 0 10px;
      height: 61px;
      border: 2px solid #db4d45;
      border-radius: 100px !important;
      color: #000;
      text-transform: capitalize;
      font-size: 16px;
      letter-spacing: 1px;
      font-weight: 700;
      border-style: solid;
      cursor: pointer;
      text-align: center;
      max-width: 200px;
      z-index: 99999;
      margin-bottom: 40px;
      &:focus,
      &:active {
        outline: 0;
      }
      &:disabled {
        background-color: #d4d1d1;
        color: #7b7a82;
        cursor: no-drop;
      }
    }
  }
`;
