import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAuthContext } from "../../context/AuthContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ProductCardForHistory from "../../components/ProductCardForHistory";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { useOrderContext } from "../../context/OrderContext";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

function SingleCustomerOrderHistory({ searchedCustomer }) {
  const { bpLoginInfo } = useAuthContext();
  let { state } = useLocation();
  const { latestOrdersBP, getLatestOrdersBP, totalPage } = useOrderContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(0);
  const [pendingPage, setPendingPage] = useState(1);
  const [deliveredPage, setDeliveredPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getLatestOrdersBP(
      currentPage,
      selectedTab,
      state?.renderFrom,
      searchedCustomer
    );
  }, [selectedTab, currentPage, state?.renderFrom, searchedCustomer]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setCurrentPage(newValue === 0 ? pendingPage : deliveredPage);
  };


  const handleNextPage = () => {
    const newPage = currentPage + 1;
    setCurrentPage(newPage);
    if (selectedTab === 0) {
      setPendingPage(newPage);
    } else {
      setDeliveredPage(newPage);
    }
  };

  const handlePreviousPage = () => {
    const newPage = currentPage - 1;
    setCurrentPage(newPage);
    if (selectedTab === 0) {
      setPendingPage(newPage);
    } else {
      setDeliveredPage(newPage);
    }
  };

  return (
    <Wrapper>
      <div className="order_history_title">
        <div className="order_history">
          <ListAltIcon />
          <h3>{t("latest_orders")}</h3>
        </div>
        <div className="tabs-container">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            className="tabs"
            TabIndicatorProps={{ className: "tab-indicator" }}
          >
            <Tab label="Pending" className="tab" />
            <Tab label="Delivered" className="tab" />
          </Tabs>
        </div>
      </div>


       {latestOrdersBP?.length > 0 ? <div className="table_wrapper">
          {latestOrdersBP?.map((item) => (
            <ProductCardForHistory key={item.id} {...item} />
          ))}

          <PaginationControls>
            <button onClick={handlePreviousPage} disabled={currentPage === 1}>
              <ChevronLeftIcon />
            </button>
            <span>
              {t("Page")} {currentPage} {t("of")} {totalPage}
            </span>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPage}
            >
              <ChevronRightIcon />
            </button>
          </PaginationControls>
        </div> : <p className="no_order_campaign"> <SentimentVeryDissatisfiedIcon/> {t('no_order_at_this_campaign',{ type: selectedTab ===0 ? 'PENDING':'DELIVERED' })}</p>}

    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 10px 0;
  position: relative;

  .checkout {
    position: fixed;
    bottom: 4%;
    left: 0;
    right: 0;
    z-index: 9999;
  }
  .order_history_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .order_history {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;
    margin: 16px 0 12px 0;
  }
  .tabs-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tabs {
    display: flex;
    gap: 5px; /* Adjust the gap between tabs */
  }

  .tab {
    font-size: 12px;
    min-width: 50px;
  }
  .tab-indicator {
    background-color: #ee3d3c;
  }
  .tab.Mui-selected {
    color: #ee3d3c;
  }
  .no_order {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
  }

  .table_wrapper {
    margin-bottom: 90px;
  }

  .no_order_campaign {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* color: #ee3d3c; */
    font-weight: 700;
    margin: 16px 0;
    padding: 10px 0;
    svg {
      color: #ee3d3c;
      font-size: 35px;
    }
  }
`;

const PaginationControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  position: absolute;
  bottom: 10;
  left: 0;
  right: 0;
  button {
    background-color: #ee3d3c;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    &:disabled {
      background-color: #cccccc;
      cursor: not-allowed;
    }
  }
`;

export default SingleCustomerOrderHistory;
